select.form-control,
.form-group input.form-control,
.data-table-search {
  border-radius: $box-radius;
  background-color: $tommy-gray-light;
  border: 1px solid #ced4da;
  height: auto;
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
}

.form-control.hidden {
  display: none;
}

.data-table-search {
  max-width: 180px;
  width: 100%;
  background-color: $tommy-gray-light !important;
  border: 1px solid #ced4da !important;
  padding-left: 40px !important;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.enhanced-dialog {
  input.form-control,
  select.form-control {
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
  }
}

.form-group {
  label,
  .form-check-label {
    font-weight: normal;
    font-size: 0.8rem;
    margin: 0 0 3px 0;
  }
}
.stock-input-container {
  display: flex;
  align-items: center;
  .preferred-stock {
    display: inline-block;
    margin-left: 5px;
    letter-spacing: 1px;
  }
  .indicator {
    display: inline-flex;
    border-radius: $box-radius;
    padding: 0.2rem 0.4rem 0.3rem 0.4rem;
    background-color: rgba(199, 199, 199, 0.2);
    color: rgba(0, 0, 0, 0.7);
    align-items: center;
    gap: 2px;
    & > span:first-child {
      font-weight: 700;
      color: black;
    }
    &.red {
      background-color: hsl(0deg, 94%, 92%);
    }
    &.green {
      background-color: hsla(140deg, 85%, 45%, 0.3);
    }
    &.yellow {
      background-color: hsl(55deg, 100%, 90%);
    }
  }
}
.table-input {
  width: 60px;
  border-radius: $box-radius;
  border: 1px solid #ced4da !important;
  padding: 0.2rem 0.4rem;
}

.form-row,
.form-group {
  .form-checkmark {
    width: 0;
    transform: scale(0);
    transform-origin: center center;
    font-size: 1.7rem;
    color: $checkmark-green;
    line-height: 24px;
    opacity: 0;
    margin: 0;
    min-width: 0;
    flex-shrink: 0;
    transition: 0.2s all ease;
    &.visible {
      width: 1.7rem;
      transform: scale(1);
      opacity: 1;
      margin-right: 5px;
    }
  }
}

.table-filters > .form-group > label {
  font-weight: bold;
}

@keyframes errorFade {
  from {
    border: 1px solid red;
  }
  to {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.form-group.file-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: $box-radius;
  border: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
  transition: 0.2s border ease, 0.2s background-color ease;
  label {
    margin: 0;
    cursor: pointer;
  }
  .label,
  .file-picker {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin: 0;
    .filename {
      font-weight: normal;
    }
  }
  .label {
    flex-shrink: 0;
  }
  .file-picker > p {
    font-size: 0.9rem;
    margin: 0 5px 0 0;
  }
  &.has-file {
    .label {
      font-weight: 500;
    }
  }
  &.dragging {
    border: 1px solid $tommy-blue;
    background-color: rgba(0, 100, 255, 0.05);
  }
  &.error {
    animation-name: errorFade;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
  .progress-bar-container {
    border-radius: 16px;
    margin: 6px 0;
    .progress-bar {
      height: 20px;
    }
  }
  input {
    display: none;
  }
}

.form-alert {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.65rem 1rem 0.8rem 1rem;
  background-color: $light-blue;
  margin: 0;
  max-height: 200px;
  transition: 0.3s all ease;
  i {
    font-size: 1.2rem;
    display: inline-block;
    margin-right: 7px;
  }
  &.hidden {
    max-height: 0;
    opacity: 0;
    padding: 0 1rem;
  }
  &.warning {
    background-color: $light-red;
  }
  &.notice {
    background-color: $light-yellow;
  }
}

.react-datepicker__aria-live {
  display: none;
}

.toggle-button {
  --font-size: 1em;
  --toggleSize: 1em;
  --widthRatio: 2.8;
  --paddingRatio: 0.25;
  line-height: 1em;
  display: flex;
  align-items: center;
  gap: 0.6em;
  cursor: pointer;
  font-size: var(--font-size);
  span {
    display: block;
  }
  .toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .toggle-container {
    width: calc(var(--toggleSize) * var(--widthRatio));
    border-radius: var(--toggleSize);
    padding: calc(var(--toggleSize) * var(--paddingRatio));
    background-color: #eee;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    margin-top: 1px;
    transition: 0.2s all ease;
    .toggle {
      width: var(--toggleSize);
      height: var(--toggleSize);
      border-radius: var(--toggleSize);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
      background-color: white;
      transform: translateX(0);
      transition: 0.2s all ease;
    }
  }
  &.active {
    .toggle-container {
      background-color: $checkmark-green;
      .toggle {
        transform: translateX(
          calc(
            (var(--widthRatio) * var(--toggleSize)) - var(--toggleSize) -
              (2 * var(--paddingRatio) * var(--toggleSize))
          )
        );
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .data-table-search {
    max-width: 100%;
  }
}
