@import 'bootstrap.scss';
@import 'variables.scss';
@import 'icons.scss';
@import 'button.scss';
@import 'form.scss';
@import 'layout.scss';
@import 'sidebar.scss';
@import 'react-datepicker.scss';
@import 'claim-detail.scss';
@import 'task-detail.scss';
@import 'wash-count-adjustments.scss';
@import 'wash-count-print.scss';
@import 'wash-count.scss';
@import 'nav.scss';
@import 'data-table.scss';
@import 'todays-tasks.scss';
@import 'doughnut-chart-module.scss';
@import 'todays-tasks.scss';
@import 'data-row.scss';
@import 'analytics.scss';
@import 'tasks.scss';
@import 'purchase-orders.scss';
@import 'purchase-order-detail.scss';
@import 'date-range-selector.scss';
@import 'fade.scss';
@import 'slide-out.scss';
@import 'slider.scss';
@import 'ellipsis-loader.scss';
@import 'focus-menu.scss';
@import '_table-edit-cell.scss';
@import 'enhanced-dialog.scss';
@import 'tabs.scss';
@import 'visual-scroll.scss';
@import 'side-nav.scss';
@import 'drawer.scss';
@import 'badge.scss';

html,
body,
#root {
  height: 100%;
}

#root.wash-club {
  --brandPrimary: #{$washclub-blue};
  --brandText: #{$washclub-blue};
  --brandPrimaryHover: #{desaturate(lighten($washclub-blue, 10), 5)};
  --brandGradient: linear-gradient(350deg, #{$washclub-blue}, #8db7e7);
}

.app-main {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  background-color: #e6e6ea;
  max-width: 100vw;
  height: 100%;
  & > * {
    position: relative;
    max-height: 100%;
  }
  main {
    overflow-y: auto;
    z-index: 0;
  }
  .page-container {
    & > .container-fluid {
      padding-left: $main-padding;
      padding-right: $main-padding;
      padding-bottom: $main-padding;
      z-index: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  .app-main {
    .page-container {
      & > .container-fluid {
        padding-left: $main-padding;
        padding-right: $main-padding;
        padding-bottom: $main-padding;
      }
    }
  }
}

.nav-top {
  height: 75px;
  padding-left: 80px;
  background: #fff;
  @include media-breakpoint-down(xs) {
    height: 50px;
    padding-left: 0;
  }
}
.nav-top-top {
  height: 75px;
  padding-left: 80px;
  background: #fff;
  @include media-breakpoint-down(xs) {
    height: 60px;
    padding-left: 0;
  }
}
.nav-top-hidden {
  @extend .nav-top;
  padding-left: 20px !important;
}

.col-top-gutter {
  margin-top: $main-padding;
}

.task-details-handle-buttons {
  display: contents !important;
}

.form-label {
  margin-bottom: 0.1rem;
  font-weight: 600;
  font-size: 14px;
}

.detail-info-label {
  margin-bottom: 0.5rem !important;
}

.truncated {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select.select {
  border: 2px solid $tommy-blue;
  color: $tommy-blue;
  border-radius: 3px;
  background: none;
  padding: 1px 20px;
  font-size: 12px;
  width: 200px;
}

@media (max-width: 620px) {
  select.select {
    width: 100% !important;
  }
}

.page-main-content {
  background: $section-background;
  padding: $section-padding;
  height: 100%;
  border-radius: $box-radius;
  box-shadow: $box-shadow-tabs;
  position: relative;
  &:first-child {
    margin-top: $main-padding;
  }

  &.nopadding {
    padding: 1.5em;
    margin-top: 0;
    border-radius: 0;
  }

  .tasks-container,
  .todays-tasks-container,
  .task-table-header,
  .reclamations,
  .purchase-orders,
  .repair-content,
  .claim-reports,
  .adjustments {
    .report-type-selector,
    .status-selector {
      margin-top: 10px;
    }

    .table-search-input {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  @media screen and (max-width: 575px) {
    &.nopadding {
      padding: 1em;
    }
  }
}

.detergent-tab-container {
  background-color: $section-background;
  padding: $section-padding;
  border-radius: $box-radius;
  position: relative;
  box-shadow: $box-shadow-tabs;
}

.no-margin-or-padding {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.shadowed {
  box-shadow: $box-shadow;
}

.bold {
  font-weight: bold !important;
}

.align-right {
  text-align: right;
}

.as-of-date {
  min-height: 64px;
}

.task-notes-section {
  max-height: 280px;
  overflow: scroll;
}

.task-note-posted {
  display: flex;
  justify-content: left;
}

.task-note {
  text-align: center;
  padding: 10px;
  border: 1px;
}

.suggestions-container {
  background-color: #fff;
  position: absolute;
  z-index: 20;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-right: 15px;
  margin-left: 15px;
  right: 0;
  left: 0;

  .suggestions-list {
    max-height: 300px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    small {
      color: lightslategray;
    }
  }

  .suggestion {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px;
    }

    li:hover {
      background: #e6e6ea;
    }
  }
}

.clickable-datarow {
  cursor: pointer;

  &:hover {
    background: $tommy-gray-light !important;
  }
}

.small-date {
  max-width: 120px;
}

.btn:disabled {
  opacity: 0.4;
}

.btn-icon {
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.jc-center {
  justify-content: center;
}

.archived-checkbox {
  label {
    font-weight: normal !important;
    vertical-align: middle;
    line-height: 32px;
    margin: 3px 0 0 0;
    white-space: nowrap;
  }
}

input.custom-checkbox {
  display: inline-block;
  margin: 0 10px 0 8px;
  transform: scale(1.4);
  vertical-align: middle;
  transition: 0.15s all ease-in-out;
}

input.custom-checkbox:focus {
  outline: 0;
  box-shadow: 0 0 1px 2px rgba(0, 123, 255, 0.25);
}

.btn-sm {
  border-radius: 0px;
}

.action-container {
  display: flex;
  justify-content: space-between;
}

.action-button {
  border-radius: 5px;

  &:first-child {
    margin-right: 5px;
  }
}

.w-50 {
  width: 50%;
}

.right-10 {
  right: 10px;
}

.left-10 {
  left: 10px;
}

.no-wrap-mobile {
  flex-wrap: nowrap;
  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
}

.readings-form {
  margin-top: 5px;
  border-radius: 3px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 1px 11px 3px 7px;
}

.multi-form-delete {
  border-radius: 3px;
  outline: none;
  color: red;

  &:hover {
    color: red;
  }
}

.todays-tasks {
  .page-action-buttons {
    text-align: right;
  }
}
.task-subtitle {
  font-size: 10px;
  opacity: 0.6;
}
.tasks-container {
  .table {
    td:first-child {
      white-space: normal;
    }
  }
}

.tasks-action-buttons {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.part-list-item {
  color: rgb(32, 151, 244);

  &:hover {
    cursor: pointer;
    filter: invert(100%);
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.page-details-content {
  background: $section-background;
  padding: 10px;
  height: 100%;
  border-radius: 3px;
  margin-top: 10px;
}

.hours-group {
  height: 300px;
  margin-top: 1rem;
}

.form-check-inline.hours {
  display: block;
  height: 100%;
  width: 100%;
}

.form-check-label {
  margin-bottom: 0.1rem;
  font-weight: 600;
  font-size: 14px;
}

.hours-container {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px 40px;
  grid-auto-flow: column;

  .hours-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);

    &.active {
      box-shadow: none;
      background: #d6d6d6;
    }
  }
}

@media (max-width: 470px) {
  .hours-container {
    grid-gap: 10px 24px;
    font-size: 12px;
  }
}

.flex {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
}

.claim-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .claim-success-instructions {
    text-align: center;
    font-weight: bold;
    font-size: 18px;

    h1 {
      margin: 30px 0;
    }
  }

  button {
    margin-top: 200px;
    width: fit-content;
    font-weight: bold;
    background: none;
    border: none;
    color: red;

    &:hover {
      background: none;
      border: none;
      color: red;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.notes {
  .notelist-item {
    background: #fff !important;
    border-top: 1px solid #eceeef;
    padding: 0.75rem;
    margin-left: 0px;
    margin-right: 0px;

    &:hover {
      background: #e6e6ea !important;
      cursor: pointer;
    }
  }

  .text-preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: gray;
    font-style: italic;
  }
}

.home-donut-chart {
  margin-top: 50px;
  width: 80%;
}

.italic {
  font-style: italic;
}

.note {
  .text {
    min-height: 100px;
    background: #fff !important;
    padding: 0.75rem;
    white-space: pre-line;
  }
}

.wash-count-table .thead {
  background-color: rgb(230, 230, 234);
}

.wash-count-table.shrinked-table,
.report-table.shrinked-table {
  table-layout: fixed;

  .first-column {
    width: 175px;
  }

  th {
    text-align: center;
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  td:not(:first-child) {
    text-align: center;
  }

  td:last-child {
    font-weight: bold;
  }
}

.table-subrows {
  cursor: pointer;
}

.shrinked-table {
  td,
  th {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}

.header-page-actions-container {
  display: flex;
  justify-content: space-between;

  h2 {
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.adjustments {
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.loader-img-container {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.date-page-actions-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.blue-button {
  border: 2px solid $tommy-blue;
  color: $tommy-blue;
  border-radius: 3px;
  background: none;
  padding: 5px 20px;
  font-size: 12px !important;

  &.solid {
    color: white;
    background-color: $tommy-blue;
  }

  &.btn:not(:disabled) {
    &:hover {
      filter: invert(100%);
      color: #2097f4;
    }
  }
}

.green-button {
  @extend .blue-button;
  border: 2px solid #28a745;
  color: #28a745;
  &.solid {
    background-color: #28a745;
  }
}

.as-of-date-container {
  margin-bottom: 0.5rem;
}

.essential-task-name-container {
  display: flex;
  align-items: center;
}

.generic-filters-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;

  span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.complete-task-aot-text {
  margin-bottom: 10px;
}

.detergent-wash-selection.selected {
  background: #d6d6d6;
}

.wash-counter-register-buttons-container {
  button {
    width: 100%;
  }
}

.historical-tank-readings-table {
  &.detergent-based {
    table-layout: fixed;

    td:first-child {
      white-space: nowrap;
    }
  }
}

.row-flex-inherit {
  display: flex;
  overflow: auto;
  flex-wrap: inherit !important;
}

.form-small-row {
  display: flex !important;
  text-align: center;

  .row-padding {
    padding-right: 3px !important;
  }

  .small-row-font {
    font-size: 12px !important;
  }

  .form-row-item {
    flex-grow: 1;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.form-group .form-full-row {
  @extend .form-small-row;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.input-padding {
  padding: 1px 0;
}

.inline-add-item-button {
  display: contents !important;
}

.redux-tab-button:not(:last-child) {
  margin-right: 4px;
}

.page-header {
  height: 7rem;
  background: rgb(210, 210, 210);
  padding-left: 30px;
  margin: 0 -30px;
  text-transform: uppercase;

  @media screen and (max-width: 576px) {
    margin: 0 -15px;
  }
}

.page-action-buttons {
  .btn:not(:first-child) {
    margin-left: 0.25rem;
  }
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 12em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor-area {
  border: 1px solid #d2d2d2;
  border-radius: 3px;
}

.editor-toolbar {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 2;
  box-sizing: border-box;
}

.toolbar-button {
  background: #fbfbfb;
  text-align: center;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background: #f3f3f3;
  }

  &:focus {
    background: #f3f3f3;
    outline: none;
  }
}

.toolbar-heading-button {
  width: 120px !important;
}

.toolbar-block-button {
  width: 37px !important;
}

.toolbar-separator {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}

.DraftEditor-editorContainer {
  padding: 5px;
  min-height: 50px;
}

.hourlyCount {
  align-items: center;
  width: 100%;
}

.counter {
  background-color: $tommy-gray-light;
  padding: 20px 0;
  border-radius: 5px;
}

.left-counter {
  float: left;
  width: 47%;
}

.right-counter {
  float: right;
  width: 47%;
}

.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.count-text {
  text-transform: uppercase;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.table {
  white-space: nowrap;

  tr {
    font-size: 12px;
  }

  td {
    padding: 0.5rem;
  }
}

.mt-6 {
  margin-top: 6%;
}

.vertically-centered-checkbox {
  display: flex;
  align-items: center;
}

.show-top-washes-button-container {
  display: flex;
  justify-content: center;

  .blue-button {
    height: 40px;
    width: 100%;
  }
}

.button-with-loader-overview-container {
  margin-top: 1rem;
}

.button-with-loader {
  margin-top: 2rem;
  border-radius: 6px !important;
  border: none;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  font-weight: 700;
  background: var(--brandGradient);

  &:disabled {
    opacity: 0.4;
  }

  &:enabled {
    &:hover {
      filter: brightness(1.1);
    }
  }
}

.button-with-loader-overview {
  @extend .button-with-loader;
  margin-bottom: 1rem;
  width: 90%;
}

.daily-numbers {
  font-weight: 700;
  font-size: 14px;
}

.chart-title-section-new {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  grid-template-rows: 0.5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'chart-name chart-view-button';
}

.primary-site-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  max-width: 200px;
  color: #000000;
  background-color: #fff;
  border-color: #fff;
  & > span {
    text-overflow: ellipsis;
    flex-shrink: 1;
    overflow: hidden;
    min-width: 0;
  }
}

.site-button {
  border-radius: $box-radius;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: $box-shadow;
}
.site-button-shadow {
  border-radius: $box-radius;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: $box-shadow;
}

.chart-title {
  grid-area: chart-title;
  font-weight: bold !important;
  font-size: large !important;
  margin-bottom: 3px;
}

.chart-title-section {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  grid-template-rows: 0.5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'chart-title overview-chart-view-button';
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.insta-club-kpi-body {
  justify-content: center;
  padding: 5px;
}

.insta-club-kpi-count {
  color: #00d563;
  font-size: 30px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.insta-club-kpi-count-large {
  @extend .insta-club-kpi-count;
  font-size: 45px;
}

.cost-per-car-plus {
  margin-top: auto;
  margin-bottom: auto;
}

.insta-club-kpi-count-negative {
  @extend .insta-club-kpi-count;
  color: #d50b00;
}

.insta-club-kpi-count-large-negative {
  @extend .insta-club-kpi-count-large;
  color: #d50b00;
}

.insta-club-kpi-title {
  font-family: 'Avenir Next';
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  margin-left: 2px;
}

.this-month {
  font-size: small;
  font-family: 'Avenir Next';
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0;
  text-align: center;
  margin-left: 2px;
}

.coming-soon {
  font-size: small;
  font-family: 'Avenir Next';
  font-weight: bold;
  margin-top: 31px;
  margin-bottom: 0;
  text-align: center;
  margin-left: 2px;
}

.insta-club-kpi-row {
  display: flex;
  overflow: auto;
  justify-content: space-evenly;

  @media screen and (max-width: 576px) {
    display: contents;
    justify-content: initial;
  }
}

.cost-per-car-info {
  border: none;
  border-radius: 26px;
  padding: 0;
  width: 26px;
  height: 26px;
  vertical-align: top;
  text-align: center;
  margin-left: 5px;
}

.cost-per-car-info i {
  line-height: 24px;
}

.claims-kpi-total-count {
  text-align: center;
  font-size: 45px;
}

.claims-bar-height {
  height: 125px;
}

.default-site-selection {
  overflow: scroll;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  margin: 30px;

  @media (max-width: 576px) {
    margin: 1rem;
  }
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 25px;
  overflow: hidden;
}

.progress-bar {
  height: 25px;
  background-color: #76c7c0;
  text-align: center;
  line-height: 25px;
  color: white;
  border-radius: 25px 0 0 25px;
  transition: width 0.3s ease;
}

// .alerts-list {
//   padding: 5px;
//   background-color: #f5f5f5;
//   border: 1px solid #e3e3e3;
//   border-radius: 4px;
//   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
//   max-height: 155px;
//   overflow: scroll;

//   .extended-height {
//     max-height: 215px !important;
//   }
// }

.alert-input-box {
  margin-right: 15px;
  margin-bottom: 10px;
  height: 20px;
  width: 20px;
}

.seven-day-cars-washed {
  section {
    gap: 4px;
  }
  .washed-card {
    display: flex;
    flex-shrink: initial;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 10px 6px;
    min-width: 60px;
  }

  .cars-washed {
    font-style: oblique;
    background-color: #ededed;
    /*border: 1px solid rgb(204, 204, 204);*/
  }

  .cars-washed-selected {
    color: #4ca55f;
    background-color: #ebfcf3;
    font-size: 14px;
    border: 1px solid #4ca55f;
    min-width: 120px;
  }
}

@media screen and (min-width: 991px) {
  .overview {
    > .row {
      > *:first-child {
        padding-right: 0.5rem;
      }
      > *:last-child {
        padding-left: 0.5rem;
      }
    }
  }
}

.date-time-overview {
  margin: $main-padding 0;
}

.survey-chart {
  display: grid;
  justify-content: center;
}

.overview-week-wash-count {
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  color: #666;
}

.chart-card,
.overview-chart-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 8px 24px -20px rgba(0, 0, 0, 0.75);
  box-shadow: $box-shadow;
  margin-bottom: $main-padding;
  padding: 1rem;
}

.overview-chart-view-button {
  grid-area: overview-chart-view-button;
  margin: 3px;
  padding: 3px;
}
.button-container {
  display: flex;
}
.close-sites-button {
  display: flex;
  align-items: right;
  margin-right: 10px;
}
.overview-wash-table-hide {
  display: none;
}

.overview-claim-chart {
  height: 150px;
  width: 550px;
}

.overview-claimCount-chart {
  padding-left: 30px;
}

.overview-claim-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.75);
}

.revenue-count-title {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #92959e;
}

.revenue-count {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #15192c;
}

.revenue-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.5fr;
  grid-template-rows: 0.7fr 0.7fr 1.6fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  @media (max-width: 1366px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.2fr 1.2fr 0.6fr;
    grid-template-rows: 1.6fr 0.3fr;
    gap: 0px 0px;
    grid-template-areas:
      'graph graph graph'
      'counterOne counterTwo .';
  }
}

.revenue-graph {
  height: 225px;
  grid-area: 1 / 1 / 4 / 3;
  @media (max-width: 1366px) {
    grid-area: graph;
  }
}

.revenue-counter-one {
  grid-area: 1 / 3 / 2 / 4;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 18px 32px -4px rgba(208, 210, 218, 0.15);
  border-radius: $box-radius;
  margin-top: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 1366px) {
    grid-area: counterOne;
  }
}

.revenue-counter-two {
  grid-area: 2 / 3 / 3 / 4;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 18px 32px -4px rgba(208, 210, 218, 0.15);
  border-radius: 12px;
  text-align: center;
  @media (max-width: 1366px) {
    grid-area: counterTwo;
  }
}
.field {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}
.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}
.field.active input {
  padding: 24px 16px 8px 16px;
}
.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #512da8;
}
.field.locked {
  pointer-events: none;
}
.field input {
  width: 100%;
  height: 33px !important;
  position: relative;
  padding: 0px 8px;
  border-color: #80808045;
  border-radius: 4px;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
  text-align: center;

  &:disabled {
    background-color: #bbb;
    color: #2d2d2d;
  }
}
.field input::-webkit-input-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input::-moz-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}
.field input + label.error {
  color: #ec392f;
}
.field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}
.tasks-textarea {
  width: 100%;
  height: 90%;
}

.custom-task-modal-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  padding-top: 10px;
}

.task-description-field {
  flex: 3;
}

.task-form-row {
  display: flex;
  justify-content: space-between;
}

.task-form-row .form-group {
  flex: 1;
  margin-right: 10px;
}
.task-description-field {
  flex: 3;
}
.detergent-readings-title {
  font-family: 'Avenir Next';
  font-size: 34px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.detergent-dialog {
  width: 1292px;
  height: 700px;
  right: 190px;
  border-radius: 24px;
  border: 1px;
  font-family: Avenir Next;
  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;

  text-align: left;
  @media (max-width: 1100px) {
    right: 100px !important;
  }
  @media (max-width: 699px) {
    right: 0px !important;
  }
}
@media (max-width: 1100px) {
  .detergent-dialog > .modal-content {
    width: 1000px !important;
  }

  .task-dialog > .modal-content {
    width: 1000px !important;
  }
}
@media (max-width: 699px) {
  .detergent-dialog > .modal-content {
    width: 400px !important;
  }

  .task-dialog > .modal-content {
    width: 400px !important;
  }
}
.modal-dialog > .detergent-dialog > .modal-title {
  font-family: Avenir Next;
  font-size: 34px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
}
.detergent-dialog > .modal-content {
  width: 1000px;
  height: 600px;
  justify-content: center;
}
.task-dialog > .modal-content {
  width: 1000px;
  height: 1000px;
  justify-content: center;
}

.task-dialog {
  width: 1292px;
  height: 700px;
  right: 190px;
  border-radius: 24px;
  border: 1px;
  font-family: Avenir Next;
  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;

  text-align: left;
  @media (max-width: 1100px) {
    right: 100px !important;
  }
  @media (max-width: 699px) {
    right: 0px !important;
  }
}

.color-picker {
  display: flex;
}

.color-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.add-color-icon {
  display: flex;
  justify-content: center;
  padding-top: 4px;
}

.selected {
  border: 2px solid #333;
}

.detergent-dialog-text {
  color: white;
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-header.custom-modal-title {
  font-size: 50px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
}

.custom-detergent-modal-title {
  font-size: 150px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
}
.detergent-button-container {
  display: flex;
  justify-content: flex-end;
}
.detergent-action-button {
  background-color: var(--brandPrimary) !important;
  border-color: var(--brandPrimary) !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 12px;
  margin-bottom: 15px;
  padding: 10px 15px;
  top: auto;
}
.detergent-title-container {
  margin-bottom: 30px;
}
.detergent-date-container {
  display: flex;
  margin-bottom: 15px;
}
.detergent-page-content {
  @extend .page-main-content;
  margin-top: 0;
  background-color: white !important;
}
.detergent-table-edge {
  border: #d9d9d9 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
}
.detergent-page-action {
  left: auto;
}
.detergent-tank-container {
  display: flex;
  flex-direction: row;
}
.detergent-tank-title {
  text-align: center;
  white-space: nowrap;
  background-color: gray;
  color: white;
  padding: 7px;
  margin-bottom: 15px;
}
.detergent-capacity-container {
  display: flex;
  justify-content: center;
}
.detergent-capacity-info {
  background-color: black;
  color: #b5b5b5;
  width: 40%;
  display: flex;
  justify-content: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.detergent-bar-chart-container {
  background: #e8e8e8;
  width: 145px;
  height: 200px;
  border-radius: 7px;
}

.custom-padding-class {
  border-spacing: 15px 25px !important;
  border-collapse: separate !important;
}

.field {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
  padding: 24px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #512da8;
}

.field.locked {
  pointer-events: none;
}

.field input {
  width: 100%;
  height: 33px !important;
  position: relative;
  padding: 0px 8px;
  border-color: #80808045;
  border-radius: 4px;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
  text-align: center;
}

.field input::-webkit-input-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input::-moz-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(137, 137, 137, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(137, 137, 137, 0.8);
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.field input + label.error {
  color: #ec392f;
}

.field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}

.detergent-readings-title-header {
  font-weight: bold;
  font-size: x-large;
  margin-right: 10px;
}

.detergent-action-button {
  background-color: var(--brandPrimary) !important;
  border-color: var(--brandPrimary) !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 12px;
  margin-bottom: 15px;
  padding: 10px 15px;
  top: auto;
  font-size: 14px;
}

.detergent-action-button-white {
  @extend .detergent-action-button;
  background-color: white !important;
  border-color: var(--brandPrimary) !important;
  color: var(--brandPrimary) !important;
}

.detergent-disabled-action-button {
  @extend .detergent-action-button;
  background-color: #858585 !important;
  border-color: #858585 !important;
}

.w-100 {
  width: 100% !important;
}

.detergent-title-container {
  margin-bottom: 30px;
}

.detergent-date-container {
  display: flex;
  margin-bottom: 15px;
}

.detergent-page-content {
  @extend .page-main-content;
  margin-top: 0;
  background-color: white !important;
}

.detergent-table-edge {
  border: #d9d9d9 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
}

.detergent-page-action {
  left: auto;
}

.detergent-tank-container {
  display: flex;
  flex-direction: row;
}

.detergent-tank-title {
  justify-content: center;
  background-color: gray;
  color: white;
  padding: 7px;
  margin-bottom: 15px;
}

.detergent-capacity-container {
  display: flex;
  justify-content: center;
}

.detergent-capacity-info {
  background-color: black;
  color: #b5b5b5;
  width: 40%;
  display: flex;
  justify-content: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.detergent-bar-chart-container {
  background: #e8e8e8;
  width: 145px;
  height: 200px;
  border-radius: 7px;
}

.detergent-text-area {
  display: flex;
  justify-content: center;
  border: #b5b5b58c 1px solid;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
  outline: none;
  resize: none;
  overflow: auto;
  ::placeholder {
    color: #b5b5b5;
    margin-right: 10px;
  }
}

.detergent-input-area {
  @extend .detergent-text-area;
  margin-bottom: 5px;
  height: 40px;
}

.overview-best-day-view-button {
  text-align: center;
  margin: 3px;
  padding: 10px;
}

.site-selection-button {
  margin-bottom: 8px;
  border-color: white;
}

.site-selection-list-area {
  display: table-cell;
}

.site-selection-list-body {
  padding: 5px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  overflow: scroll;
  max-height: 215px;
}

.site-selection-site-item {
  padding: 5px;
  float: left;
  margin: 0.25rem;
  border-radius: 4px;
}

.inventory {
  .table td {
    vertical-align: middle;
    .inventory-name {
      display: flex;
      align-items: center;
      .inventory-priority {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: rgb(222, 222, 225);
        text-align: center;
        margin-right: 6px;
        i {
          font-size: 0.8rem;
          display: inline-block;
          color: grey;
          width: 20px;
          height: 20px;
          line-height: 20px;
          position: absolute;
          top: -1px;
          left: 0;
        }
      }
    }
  }
}

.inventory,
.essential-parts-container {
  .table td:nth-child(3) {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .table td:nth-child(5),
  .table td:nth-child(6) {
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1200px) {
  .inventory,
  .essential-parts-container {
    .table td:nth-child(3) {
      max-width: 200px;
    }
  }
}

.inventory-delete {
  .modal-body {
    padding: 0;
    .delete-content {
      padding: 1rem;
      input {
        -ms-transform: scale(1.3); /* IE */
        -moz-transform: scale(1.3); /* FF */
        -webkit-transform: scale(1.3); /* Safari and Chrome */
        -o-transform: scale(1.3); /* Opera */
        transform: scale(1.3);
        margin: 0 5px;
      }
    }
  }
  &.warning {
    .confirm {
      background-color: rgb(214, 0, 0);
      &:hover {
        background-color: red;
      }
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
}

.parts-file-upload {
  .file-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dt-table-scroll {
    max-height: 625px;
  }
}

@media (max-width: 1135px) {
  .shrinked-table {
    .first-column {
      width: auto;
    }
  }
  .report-table.shrinked-table {
    td {
      font-size: 12px !important;
    }

    th {
      font-size: 10px !important;
    }
  }
}

@media (max-width: 1000px) {
  .shrinked-table:not(.adjustment-table) {
    table-layout: auto !important;
  }

  .redux-tab-button,
  .tab-button {
    flex: 1;
  }

  .overview-wash-chart {
    height: 350px;
  }
}

@media (max-width: 991px) {
  .wash-chart-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 858px) {
  .as-of-date-container {
    width: 100%;
  }
}

@media (max-width: 810px) {
  .adjustments-table-button {
    button {
      width: 100%;
      padding: 10px 0 !important;
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 800px) {
  .wash-chart {
    height: fit-content !important;
    padding: 10px 0 10px !important;

    & > div:first-child {
      display: none;
    }
  }
  .wash-count-table.shrinked-table {
    td,
    th {
      font-size: 12px;
    }
  }

  .overview-wash-chart {
    height: 350px;
  }
}

@media (max-width: 750px) {
  .historical-tank-readings-table {
    th {
      font-size: 10px;
    }

    td {
      font-size: 12px;
    }

    th,
    td {
      padding: 0.35rem;
    }
  }

  .detergent-tab-container {
    padding: 15px !important;
  }
}

@media (max-width: 670px) {
  .todays-tasks {
    display: block;
    .page-action-buttons {
      text-align: left;
      margin: 10px 0;
    }
  }
  .tasks-buttons-container {
    flex-direction: column;
    align-items: stretch !important;

    .page-action-buttons {
      width: 100%;

      button {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }

  .generic-filters-container {
    width: 100% !important;

    span {
      width: 100%;
    }
  }
}

@media (max-width: 575px) {
  .date-page-actions-container {
    margin-right: 15px;
    margin-left: 15px;
    flex-direction: column;

    span {
      text-align: center;
      margin-bottom: 15px;
    }

    & > .page-action-buttons {
      & > button {
        width: 100%;
        padding: 5px !important;
      }
    }
  }
  .adjustments {
    & > .page-action-buttons {
      margin: 0 20px 20px 20px !important;

      & > button {
        width: 100%;
        padding: 5px !important;
      }
    }
  }
  .wash-tables {
    h2 {
      text-align: center;
    }

    padding: 20px 10px !important;
  }
  .shrinked-table {
    th,
    td {
      padding: 0.25rem;
    }
  }
  .header-page-actions-container {
    justify-content: space-around;
  }
  .page-action-buttons {
    .btn {
      flex: 1;
    }
  }
}

.claim-status-change-modal > .modal-content > .modal-body {
  white-space: pre-line;
}

@media (min-width: 700px) {
  .claim-status-change-modal {
    min-width: 550px;
  }
}
