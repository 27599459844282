.enhanced-dialog {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1.5em;
  &.medium {
    max-width: 800px;
    & > .modal-content {
      height: 600px !important;
    }
  }
  &.low {
    max-width: 800px;
    & > .modal-content {
      height: 300px !important;
    }
  }
  &.narrow {
    max-width: 500px;
    & > .modal-content {
      height: auto !important;
    }
  }
  .modal-body {
    padding: 0;
    .collapse-padding {
      padding: 1rem;
      .form-row {
        padding: 0;
      }
    }
  }
  &.padded {
    .modal-body {
      padding: 2rem;
    }
  }
  .modal-title,
  .form-title {
    font-weight: normal;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .modal-header {
    .close {
      margin: -3px 0 0 0;
      padding: 0;
    }
  }
  & > .modal-content {
    width: 100% !important;
    height: 800px !important;
    overflow-y: auto;
    .form-title {
      border-bottom: 1px solid #dee2e6;
      padding: 0.7rem 1rem;
      &.collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        padding: 0;
        &:focus {
          color: black;
          background-color: rgba(130, 138, 145, 0.4);
          box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.4);
        }
      }
    }
    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      margin: 0;
      padding: 1rem 1rem 0 1rem;
      &.half {
        grid-template-columns: 1fr 1fr;
      }
      &.full {
        grid-template-columns: 1fr;
      }
      .form-group {
        margin-right: 0;
        label {
          margin-bottom: 6px;
        }
        input,
        textarea,
        select {
          border: 1px solid #ced4da;
          background-color: rgba(0, 0, 0, 0.04);
        }
        textarea {
          min-height: 120px;
        }
        .react-datepicker-wrapper,
        .react-datepicker__input-container {
          display: block;
        }
      }
      .interval-description {
        margin-top: 2rem;
      }
      .readings-form {
        margin-top: 1rem;
        box-shadow: none;
        .form-group {
          margin: 0;
          align-items: center;
        }
        .multi-form-delete {
          margin: 0 0 0 5px !important;
        }
      }
      .model-year {
        .multi-form-delete {
          margin: 28px 0 0 5px !important;
        }
      }
      .btn-outline-success {
        border-radius: 4px;
        border: 1px solid hsla($green-hue, 76%, 45%, 1);
        color: hsla($green-hue, 76%, 45%, 1);
        &:hover {
          color: white;
          background-color: hsla($green-hue, 76%, 45%, 1);
        }
      }
    }
    .linked-tasks {
      .btn-outline-secondary {
        width: 40px;
        height: 40px;
        color: black;
        border-color: grey;
        &:focus,
        &:hover {
          background-color: rgba(130, 138, 145, 0.4);
        }
        span {
          display: inline-block;
          margin: 0 0 4px 0;
          font-weight: bold;
        }
      }
      .inline-add-item-button {
        > div {
          display: flex;
          div:not(:last-child) {
            flex-basis: 47%;
            .css-azbaz-container {
              width: auto;
            }
          }
          .close {
            color: red;
          }
        }
      }
    }
    label {
      font-size: 14px;
    }
    .DraftEditor-editorContainer {
      padding: 1rem;
      min-height: 100px;
      border: 1px solid #ced4da;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 4px 4px;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    &.full-width {
      /*display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.75rem;*/
      gap: 0.75rem;
      > * {
        flex: 1 0 auto;
      }
    }
    .submit-button {
      width: auto;
      font-size: 1.2rem;
      border-radius: 4px;
      color: white;
      padding: 0.6rem 1.2rem;
      margin: 0;
      &.confirm {
        flex-basis: 40%;
        background-color: hsla($green-hue, 76%, 45%, 1);
        border: 1px solid hsla($green-hue, 76%, 45%, 1);
      }
      &.delete {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #be1f24;
        color: #be1f24;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .enhanced-dialog {
    & > .modal-content {
      .form-row {
        grid-template-columns: 1fr 1fr;
      }
    }
    .modal-footer,
    .modal-footer.full-width {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.75rem;
    }
  }
}
@media screen and (max-width: 500px) {
  .enhanced-dialog {
    & > .modal-content {
      .form-row,
      .form-row.half {
        grid-template-columns: 1fr;
      }
    }
  }
}
