//These variables manage multiple tab components
$tab-font-size: 0.9rem;
$tab-width: 150px;
$tab-margin: 3px;
$tab-background: linear-gradient(0deg, rgb(230, 230, 234), $section-background);
$tab-background-active: $section-background;
$tab-radius: $box-radius $box-radius 0 0;
$tab-padding: 0.5rem 1rem;

//Multiple components are being targeted, so some CSS is duplicated
.container-fluid {
  & > .content-container .nav-tabs.restyled,
  & > .nav-tabs.restyled {
    border: none;
    margin-top: $main-padding;
    gap: $tab-margin;
    border-radius: $tab-radius;
    position: relative;
    .nav-item {
      font-size: $tab-font-size;
      margin: 0 !important;
      text-align: center;
      border: none;
      flex-basis: $tab-width;
      box-shadow: $box-shadow;
      .nav-link {
        padding: $tab-padding;
        margin-bottom: 0;
        background-color: $section-background;
        background: $tab-background;
        border: none;
        border-radius: $tab-radius;
        &.active {
          font-weight: bold;
          background: $tab-background-active;
          color: black !important;
        }
      }
    }
  }
}

.tabs-container {
  display: flex;
  gap: $tab-margin;
  margin-top: $main-padding;
  position: relative;
  .tab-button {
    flex-basis: $tab-width;
    border: none;
    border-radius: $tab-radius;
    white-space: normal;
    outline: none;
    font-size: $tab-font-size;
    background: $tab-background;
    box-shadow: $box-shadow;
    margin: 0 !important;
    padding: $tab-padding;
    &.active {
      font-weight: bold;
      background: $tab-background-active;
      color: black !important;
    }
    &:focus {
      box-shadow: $box-shadow;
    }
  }
}

.detergent-override {
  .nav {
    border: none;
    .nav-item {
      font-size: 0.75rem;
      border: none;
      button {
        padding: $button-padding-top $button-padding-sides $button-padding-bottom
          $button-padding-sides;
        border-radius: $box-radius;
        background-color: transparent;
        border: 1px solid var(--brandPrimary);
        color: var(--brandPrimary) !important;
        &.active {
          background-color: var(--brandPrimary);
          color: white !important;
        }
      }
    }
  }
  .content {
    padding-top: 0;
  }
}

.powerbi-button-container {
  justify-content: flex-start;
  margin: $main-padding 0;
  button {
    padding: $button-padding-top $button-padding-sides $button-padding-bottom $button-padding-sides;
    background-color: white;
    border-color: white;
    box-shadow: none;
    border-radius: $box-radius;
    &:first-child:not(:last-child) {
      border-radius: $box-radius 0 0 $box-radius;
    }
    &:last-child:not(:first-child) {
      border-radius: 0 $box-radius $box-radius 0;
    }
    &:hover {
      box-shadow: none;
      background-color: white;
    }
    .powerbi-active {
      &:hover {
        background-color: var(--brandPrimary);
      }
    }
  }
}

//Swap tabs from inline style to equally spaced
@media screen and (max-width: 1000px) {
  .container-fluid {
    & > .content-container .nav-tabs.restyled,
    & > .nav-tabs.restyled {
      .nav-item,
      .tab-button {
        font-size: 0.8rem;
        flex: 1 1;
      }
    }
  }
  .tabs-container {
    .tab-button {
      font-size: 0.8rem;
    }
  }
}

//Make the active class and tabs stackable for smaller screens
@media screen and (max-width: 600px) {
  .container-fluid {
    & > .content-container .nav-tabs.restyled,
    & > .nav-tabs.restyled {
      flex-wrap: wrap;
      .nav-item {
        .nav-link {
          &.active {
            background: linear-gradient(0deg, rgb(230, 230, 234), white);
          }
        }
      }
    }
  }
  .tabs-container {
    flex-wrap: wrap;
    .tab-button {
      &.active {
        background: linear-gradient(0deg, rgb(230, 230, 234), white);
      }
    }
  }
}
